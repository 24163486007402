<template>
    <div id="login">
        <div class="login_content">
            <div class="title">亿动赛事管理平台</div>
            <el-form ref="form" :model="form" :rules="rules" label-width="80px">
                <el-form-item label="账号:" prop="userName">
                    <el-input v-model="form.userName" placeholder="手机号/用户名" @keyup.enter.native="login"></el-input>
                </el-form-item>
                <el-form-item label="密码:" prop="password">
                    <el-input type="password" v-model="form.password" placeholder="6-20位密码" show-password @keyup.enter.native="login"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-row type="flex" justify="space-between" align="middle">
                        <el-col :span="9">
                            <el-checkbox v-model="form.checked">记住账号</el-checkbox>
                        </el-col>
                        <el-col :span="5">
                            <!-- <el-link type="primary" href="/">忘记密码</el-link> -->
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="login" style="width:200px;" :loading="loginText == '正在登录'">{{loginText}}</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
import md5 from 'js-md5'
import {
    LoginByPassword
} from '@/api'
export default {
    data(){
        return{
            form:{
                userName:localStorage.getItem('UN') || "",
                password:"",
                checked:true
            },
            rules:{
                userName:[
                    { required: true, message: '请输入账号', trigger: 'blur' }
                ],
                password:[
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { min: 6, max: 20, message: '请输入6-20位密码', trigger: 'blur' },
                    { validator: (rule, value, callback) =>{
                        if(!(/^[A-Za-z0-9]+$/.test(value))){
                            return callback(new Error('密码格式有误'));
                        }else{
                            return callback();
                        }
                    }, trigger: 'blur' }
                ]
            },
            loginText:'登录'
        }
    },
    created(){
        this.$store.commit('removeUserInfo');
        this.$store.commit('removeLeagueInfo');
    },
    methods:{
        login(){
            this.loginText = "正在登录";
            this.$refs['form'].validate((valid)=>{
                if(valid){
                    LoginByPassword({
                        LoginName: this.form.userName,
                        InputPasswordHashed: md5(this.form.password),
                        ManagerUserType:2
                    }).then((data) => {
                        this.loginText = "登录";
                        if(data.Code == 0){
                            this.$message.success('登录成功');
                            if(this.form.checked){
                                localStorage.setItem('UN',this.form.userName);
                            }
                            this.$store.commit('setUserInfo',data.ManagerUser);
                            this.$router.replace('/SelectLeague');
                        }
                    }).catch(() => {
                        this.loginText = "登录";
                    })
                }else{
                    this.loginText = "登录";
                }
            })
        }
    }
}
</script>
<style scoped>
#login{
    width: 100%;
    height: 100%;
    background-color: #101c35;
    background-image: url('~@/assets/images/bg.jpg');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.title{
    text-align: center;
    line-height: 50px;
    font-size: 24px;
    margin-bottom: 20px;
}
.login_content{
    width: 350px;
    height: auto;
    padding: 20px 40px;
    padding-left: 30px;
    background: #ffffff;
    border-radius: 15px;
    margin-top: -100px;
}
</style>